import { Buffer } from "buffer"

import { GeneralSettingsInterface } from "@app/features/general-settings/types"

type VCardProps = {
  name: string
  phone?: string
  whatsapp?: string
  telegram?: string
  site?: string
  facebook?: string
  instagram?: string
  vkontakte?: string
  youtube?: string
  vimeo?: string
  photographyDirection?: string
}

const mapGeneralSettingsToVCardProps = (generalSettings: GeneralSettingsInterface): VCardProps => ({
  name: generalSettings.name,
  phone: generalSettings.phone,
  whatsapp: generalSettings.whatsapp,
  telegram: generalSettings.telegram,
  site: generalSettings.site,
  facebook: generalSettings.facebook,
  instagram: generalSettings.instagram,
  vkontakte: generalSettings.vkontakte,
  youtube: generalSettings.youtube,
  vimeo: generalSettings.vimeo,
  photographyDirection: generalSettings.photographyDirection,
})

const formatUrl = (type: string, url: string) => `URL;TYPE=${type}:${url}`

const getSocialLinks = (vCardProps: VCardProps): string[] =>
  [
    vCardProps.facebook && formatUrl("Facebook", vCardProps.facebook),
    vCardProps.instagram && formatUrl("Instagram", vCardProps.instagram),
    vCardProps.vkontakte && formatUrl("VK", vCardProps.vkontakte),
    vCardProps.youtube && formatUrl("Youtube", vCardProps.youtube),
    vCardProps.vimeo && formatUrl("Vimeo", vCardProps.vimeo),
    vCardProps.whatsapp && formatUrl("Whatsapp", `${vCardProps.whatsapp.replace(/[\s\-\(\)]/g, "")}`),
    vCardProps.telegram && formatUrl("Telegram", `${vCardProps.telegram.replace(/[\s\-\(\)]/g, "")}`),
    vCardProps.site && formatUrl("Website", vCardProps.site),
  ].filter(Boolean)

const getBase64Image = async (avatarLink: string): Promise<string> => {
  const response = await fetch(avatarLink)
  const blob = await response.blob()
  const arrayBuffer = await blob.arrayBuffer()
  const buffer = Buffer.from(arrayBuffer)
  return buffer.toString("base64")
}

const getVCardFields = (vCardProps: VCardProps, options?: { avatarLink?: string; base64EncodedImage?: string }) => {
  const { avatarLink, base64EncodedImage } = options || {}
  const socialLinks = getSocialLinks(vCardProps)

  const fields: Array<[boolean, string]> = [
    [true, "BEGIN:VCARD"],
    [true, "VERSION:3.0"],
    [true, `N:;${vCardProps.name};;;`],
    [true, `FN:${vCardProps.name}`],
    [!!avatarLink && !!base64EncodedImage, `PHOTO;TYPE=JPEG;ENCODING=BASE64:${base64EncodedImage}`],
    [!!vCardProps.photographyDirection, `ORG:${vCardProps.photographyDirection}`],
    [!!vCardProps.phone, `TEL;TYPE=CELL:${vCardProps.phone}`],
    ...socialLinks.map((link) => [true, link] as [boolean, string]),
    [true, "END:VCARD"],
  ]

  return fields.filter(([condition]) => condition).map(([, value]) => value)
}

const generateDesktopVCard = (generalSettings: GeneralSettingsInterface) => {
  const vCardProps = mapGeneralSettingsToVCardProps(generalSettings)

  if (!vCardProps.name) return ""

  const fields = getVCardFields(vCardProps)

  return fields.join("\n")
}

const generateMobileVCard = async (generalSettings: GeneralSettingsInterface, avatarLink?: string) => {
  const vCardProps = mapGeneralSettingsToVCardProps(generalSettings)

  if (!vCardProps.name) return ""

  const base64EncodedImage = avatarLink ? await getBase64Image(avatarLink) : null

  const fields = getVCardFields(vCardProps, { avatarLink, base64EncodedImage })

  return fields.join("\n")
}

const downloadVCard = (vCardText: string, name: string) => {
  const element = document.createElement("a")
  const file = new Blob([vCardText], { type: "text/vcard;charset=utf-8" })
  element.href = URL.createObjectURL(file)
  element.download = `${name}.vcf`
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

export { downloadVCard, generateDesktopVCard, generateMobileVCard, getBase64Image }
