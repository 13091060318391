import { FC } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { useFavoriteListsStore } from "@app/features/favorites/store"
import { Button } from "@app/ui/button/button"
import { VisitCardArrow } from "@app/ui/icons/visit-card-arrow"

import styles from "./contact-photographer.module.scss"

type Props = {
  handleContactClick: () => void
  className?: string
  isFotoInside?: boolean
  isFavoritesSelectionCompleted?: boolean
}

const ContactPhotographer: FC<Props> = ({
  handleContactClick,
  isFotoInside,
  isFavoritesSelectionCompleted,
  className,
}) => {
  const i18n = useI18n()
  const likedPhotoCount = useFavoriteListsStore((state) => state.likedPhotoCount)

  if (likedPhotoCount > 0 && !isFavoritesSelectionCompleted) return null

  return (
    <Button
      className={clsx(styles["visit-card"], isFotoInside && styles["photo-inside-button"], className)}
      skin="light"
      onClick={handleContactClick}
    >
      <VisitCardArrow width={24} height={24} fill={"#000"} />
      <span className={clsx(styles["contact-text"], isFotoInside && styles["photo-inside-text"])}>
        {i18n.t("contactPhotographer.button")}
      </span>
    </Button>
  )
}

export { ContactPhotographer }
